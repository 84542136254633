import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Image, Tag, Typography } from "antd";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { notifyError } from "../../routes/ProtectedRoute";
import { singleOrder } from "../../utils/api/orders";
import { getDateTime } from "../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./styles.module.css";

const { Title } = Typography;

const statusChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return "blue";

    case "not-assigned":
      return "purple";

    case "cancelled":
      return "red";

    case "new":
      return "gold";

    case "completed":
      return "green";
    case "cod":
      return "red";

    default:
      return "green";
  }
};

function Index({ data }) {
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    const abortCont = new AbortController();
    if (data.shipments_unique_id) {
      singleOrder(data.shipments_unique_id, abortCont.signal)
        .then((res) => {
          setOrderData(res.data);
        })
        .catch((err) => {
          if (err.message === "canceled") {
            //console.log("Fetch Aborted");
          } else {
            axiosErrorHandler(err, notifyError);
          }
        })
        .finally(() => {});
    }
    return () => abortCont.abort();
  }, [data.shipments_unique_id]);

  const tagChooser = (tags) => {
    switch (tags) {
      case "inactive":
        return "red";

      default:
        return "green";
    }
  };

  const iconChooser = (tags) => {
    switch (tags) {
      case "Ongoing":
        return <SyncOutlined spin />;

      case "Cancelled":
        return <CloseCircleOutlined />;

      case "new":
        return <ClockCircleOutlined />;

      case "Delivered":
        return <CheckCircleOutlined />;

      default:
        return <CheckCircleOutlined />;
    }
  };

  return (
    <div
      className={cx(
        styles.container,
        orderData?.shipment?.delivery_type === "cod" ? styles.cod : styles.notCod
      )}
    >
      <div className={styles.detailscontain}>
        {orderData?.shipment?.delivery_type === "cod" && (
          <div className={styles.img}>
            <Image
              className={styles.image}
              src={process.env.REACT_APP_SERVER_URL + orderData?.shipment?.cod?.bill_photo}
            ></Image>
          </div>
        )}

        <div>Status:</div>
        <Tag
          color={tagChooser(orderData?.status)}
          icon={iconChooser(orderData?.status)}
          style={{ textTransform: "capitalize" }}
        >
          {orderData?.shipment?.shipment_status}
        </Tag>
        <div>Shipments Id:</div>
        <div>{orderData?.shipment?.shipments_unique_id}</div>
        <div>Pick Location:</div>
        <div>{orderData?.shipment?.pick_location}</div>
        <div> Drop Location:</div>
        <div>{orderData?.shipment?.drop_location}</div>
        <div>Sender Name:</div>
        <div>{orderData?.shipment?.sender}</div>

        <div>Phone :</div>
        <div>{orderData?.shipment?.sender_number}</div>
        <div>Order Date:</div>
        <div>{getDateTime(orderData?.shipment?.created_at).date}</div>
        <div>Order Time:</div>
        <div>{getDateTime(orderData?.shipment?.created_at).time}</div>
        <div>Reciever Name:</div>
        <div>{orderData?.shipment?.receiver_name}</div>

        <div>Receiver No:</div>
        <div>{orderData?.shipment?.receiver_number}</div>
        <div>Delivery Type:</div>
        <div className={styles.textCapital}>
          <Tag
            color={statusChooser(orderData?.shipment?.delivery_type)}
            icon={iconChooser(orderData?.shipment?.delivery_type)}
          >
            {orderData?.shipment?.delivery_type}
          </Tag>
        </div>
        {orderData?.shipment?.delivery_type === "cod" && <div>Total:</div>}
        {orderData?.shipment?.delivery_type === "cod" && (
          <div>Rs: {orderData?.shipment?.cod?.total}</div>
        )}
        {orderData?.shipment?.delivery_type === "cod" && <div>Shipment Charge:</div>}
        {orderData?.shipment?.delivery_type === "cod" && (
          <div>Rs: {orderData?.shipment?.cod?.shipment_charge}</div>
        )}
        {orderData?.shipment?.delivery_type === "cod" && <div>Payment Status:</div>}
        {orderData?.shipment?.delivery_type === "cod" && (
          <div>{orderData?.shipment?.cod?.payment_status}</div>
        )}
        {/* <div>Current Location:</div>
        <div>{orderData?.last_location}</div> */}
      </div>

      <div className={styles.margin} />
      <Title level={3}>Item</Title>

      <table>
        <tbody>
          <tr className={styles.headerTable}>
            <td className={styles.td}>ID</td>
            <td className={styles.td}>Item Name</td>
            <td className={styles.td}>Item Quantity</td>
            <td className={styles.td}>Item Price</td>
          </tr>
          {orderData?.shipment?.shipmentitems?.map((dat, i) => (
            <tr
              key={i}
              className={i % 2 === 0 ? styles.tableBodyColorEven : styles.tableBodyColorOdd}
            >
              <td className={styles.td}>{dat.id}</td>
              <td className={styles.td}>{dat.item_name}</td>
              <td className={styles.td}>{dat.item_quantity}</td>
              <td className={styles.td}>{dat.item_price}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.margin} />
      <Title level={3}>Delivery Log</Title>

      <table className={styles.table}>
        <tbody>
          <tr className={styles.headerTable}>
            <td className={styles.td}>S.N.</td>

            <td className={styles.td}>Pickup Address</td>
            <td className={styles.td}>Delivery Address</td>
            <td className={styles.td}>Delivered Time</td>

            <td className={styles.td}>Status</td>
          </tr>
          {orderData?.tracking?.map((dat, i) => (
            <tr
              key={i}
              className={i % 2 === 0 ? styles.tableBodyColorEven : styles.tableBodyColorOdd}
            >
              <td className={styles.td}>{i + 1}</td>
              <td className={styles.td}>{dat?.receive_from}</td>
              <td className={styles.td}>{dat?.deliver_to}</td>
              <td className={styles.td}>
                {dat?.delivered_time ? dat?.delivered_time : "Not Delivered"}
                {dat?.deliver_time?.split("T")[1]?.split(":")[1]}.
                {dat?.deliver_time?.split("T")[1]?.split(":")[1]}
              </td>

              <td className={styles.td}>{dat.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Index;
