import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Image, Input, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { notifyError } from "../../routes/ProtectedRoute";
import { trackOrder } from "../../utils/api/orders";
import { getDateTime } from "../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./styles.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return <SyncOutlined spin />;

    case "not-assigned":
      return <CloseCircleOutlined />;

    case "cancelled":
      return <CloseCircleOutlined />;

    case "new":
      return <ClockCircleOutlined />;

    case "completed":
      return <CheckCircleOutlined />;
    case "cod":
      return "";
    case "delivery-only":
      return "";

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return "blue";

    case "not-assigned":
      return "purple";

    case "cancelled":
      return "red";

    case "new":
      return "gold";

    case "completed":
      return "green";
    case "cod":
      return "red";

    default:
      return "green";
  }
};

function TrackOrders() {
  const { Search } = Input;
  const { Title } = Typography;

  const [search, setSearch] = useState("");
  const [orderedData, setOrderedData] = useState();
  const [trackingData, setTrackingData] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const fetchOrderItems = async (signal) => {
    setLoading(true);
    await trackOrder(search, signal)
      .then((res) => {
        if (res.status === "Success") {
          setOrderedData(res.data.shipment);
          setTrackingData(res.data.tracking);
          setLoading(false);
        }
      })
      .catch((err) => {
        setOrderedData(null);
        setTrackingData(null);
        if (err.message === "canceled") {
          console.log("Fetch Aborted");
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  const abortCont = new AbortController();

  const handleSubmit = () => {
    if (search !== "") {
      fetchOrderItems(abortCont.signal);
    } else {
      setOrderedData(null);
    }
  };

  useEffect(() => {
    return () => abortCont.abort();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Track Orders</div>
        <div className={styles.subtitle}>Enter id to track order</div>
      </div>
      <div>
        <Search
          placeholder="Enter shipment id"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 300, marginBottom: "1em" }}
          onPressEnter={handleSubmit}
          onSearch={handleSubmit}
          loading={loading}
        />
      </div>
      {loading ? (
        <Loading />
      ) : orderedData ? (
        <div className={styles.detailContainer}>
          <div className={styles.detailscontain}>
            <div>Status:</div>
            <Tag
              color={tagChooser(orderedData?.shipment_status)}
              icon={iconChooser(orderedData?.shipment_status)}
              style={
                tagChooser(orderedData?.shipment_status) === "purple"
                  ? { maxWidth: "120px" }
                  : tagChooser(orderedData?.status) === "green" ||
                    tagChooser(orderedData?.shipment_status) === "blue" ||
                    tagChooser(orderedData?.shipment_status) === "red"
                  ? { maxWidth: "100px" }
                  : { maxWidth: "70px" }
              }
            >
              {orderedData?.shipment_status}
            </Tag>
            <div>Shipments Id:</div>
            <div>{orderedData?.shipments_unique_id}</div>
            <div>Pick Location:</div>
            <div>{orderedData?.pick_location}</div>
            <div> Drop Location:</div>
            <div>{orderedData?.drop_location}</div>
            <div>Sender Name:</div>
            <div>{orderedData?.sender}</div>
            <div>Phone :</div>
            <div>{orderedData?.sender_number}</div>
            <div>Order Date:</div>
            <div>{getDateTime(orderedData?.created_at).date}</div>
            <div>Order Time:</div>
            <div>{getDateTime(orderedData?.created_at).time}</div>
            <div>Reciever Name:</div>
            <div>{orderedData?.receiver_name}</div>
            <div>Receiver No:</div>
            <div>{orderedData?.receiver_number}</div>
            <div>Delivery Type:</div>
            <div className={styles.textCapital}>
              <Tag
                color={tagChooser(orderedData?.delivery_type)}
                icon={iconChooser(orderedData?.delivery_type)}
              >
                {orderedData?.delivery_type}
              </Tag>
            </div>
            {orderedData?.delivery_type === "cod" && <div>Total:</div>}
            {orderedData?.delivery_type === "cod" && <div>Rs: {orderedData?.total}</div>}
            {orderedData?.delivery_type === "cod" && <div>Shipment Charge:</div>}
            {orderedData?.delivery_type === "cod" && (
              <div>Rs: {orderedData?.cod?.shipment_charge}</div>
            )}
            {orderedData?.delivery_type === "cod" && <div>Payment Status:</div>}
            {orderedData?.delivery_type === "cod" && <div>{orderedData?.cod?.payment_status}</div>}
            {orderedData?.delivery_type === "cod" && (
              <div className={styles.img}>
                <Image
                  className={styles.image}
                  src={process.env.REACT_APP_SERVER_URL + orderedData?.cod?.bill_photo}
                ></Image>
              </div>
            )}
          </div>

          <div className={styles.margin} />
          <Title level={3}>Item</Title>

          <table className={styles.table}>
            <tbody>
              <tr className={styles.headerTable}>
                <td className={styles.td}>ID</td>
                <td className={styles.td}>Item Name</td>
                <td className={styles.td}>Item Quantity</td>
                <td className={styles.td}>Item Price</td>
              </tr>
              {orderedData?.shipmentitems?.map((dat, i) => (
                <tr
                  key={i}
                  className={i % 2 === 0 ? styles.tableBodyColorEven : styles.tableBodyColorOdd}
                >
                  <td className={styles.td}>{dat.id}</td>
                  <td className={styles.td}>{dat.item_name}</td>
                  <td className={styles.td}>{dat.item_quantity}</td>
                  <td className={styles.td}>{dat.item_price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.margin} />
          <Title level={3}>Delivery Log</Title>

          <table className={styles.table}>
            <tbody>
              <tr className={styles.headerTable}>
                <td className={styles.td}>S.N.</td>
                <td className={styles.td}>Pickup Address</td>
                <td className={styles.td}>Pickup time</td>
                <td className={styles.td}>Delivery Address</td>
                <td className={styles.td}>Delivered Time</td>
                <td className={styles.td}>Status</td>
              </tr>
              {trackingData?.map((dat, i) => (
                <tr
                  key={i}
                  className={i % 2 === 0 ? styles.tableBodyColorEven : styles.tableBodyColorOdd}
                >
                  <td className={styles.td}>{i + 1}</td>
                  <td className={styles.td}>{dat?.receive_from}</td>
                  <td className={styles.td}>{dat?.received_time}</td>
                  <td className={styles.td}>{dat?.deliver_to}</td>
                  <td className={styles.td}>{dat?.delivered_time}</td>
                  <td className={styles.td}>{dat.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>Enter a valid tracking number</div>
      )}
    </div>
  );
}

export default TrackOrders;
